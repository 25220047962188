<template>
	<div>
		<!-- Page Header -->
		<div class="d-flex justify-space-between align-center mb-3">
			<h3 class="text-h4 font-weight-light">Services</h3>

			<div class="d-inline-flex align-center">
				<div v-if="search['show']" class="d-inline-block mr-3">
					<v-text-field class="fsk-v-input" v-model="search['input']" name="search" type="search" dense hide-details="auto" prepend-inner-icon="mdi-magnify" outlined clearable></v-text-field>
				</div>
				<v-btn v-if="!search['show']" icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click="search['show'] = true"><v-icon>mdi-magnify</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click.stop="showForm"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click="resetTable()"><v-icon>mdi-cached</v-icon></v-btn>
			</div>
		</div>

		<!-- Page Body -->
		<div>
			<v-card :loading="loading" v-if="!reset">
				<v-card-text>
					<v-data-table
						class="fsk-v-data-table"
						:headers="headers"
						:items="table"
						:search="search['input']"
					>
						<template v-slot:[`item.name`]="{ item }">
							<div class="py-3">
								<div>{{ item['name'] }}</div>
								<div class="caption">{{ item['code'] }}</div>
							</div>
						</template>
						<template v-slot:[`item.status`]="{ item }">
							<div class="text-center">
								<v-chip :color="getStatusColor(item.status)" x-small outlined>{{ item['status'] }}</v-chip>
							</div>
						</template>
						<template v-slot:[`item.date`]="{ item }">
							<div>{{ $moment.utc(item['date']).format('DD MMM YYYY') }}</div>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<div class="v-data-table-actions">
								<v-menu bottom left>
									<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
									</template>

									<v-list>
									<v-list-item dense @click="editItem(item)">
										<v-list-item-title>View / Edit</v-list-item-title>
									</v-list-item>
									<v-list-item dense @click="updateStatus(item)">
										<v-list-item-title>Update Status</v-list-item-title>
									</v-list-item>
									<v-list-item dense @click="deleteItem(item)">
										<v-list-item-title>Delete</v-list-item-title>
									</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</div>

		<!-- Create Dialog -->
		<v-dialog
			v-model="form['show']"
			persistent
			scrollable
			content-class="fsk-v-create-dialog"
		>
			<v-card :loading="form['loading']" :disabled="form['loading']">
				<v-card-title>
					<span v-if="form['create']" class="headline">New Service</span>
					<span v-else class="headline">Edit Service</span>
				</v-card-title>
				<v-card-text style="max-height: 80vh;">
					<v-row>
						<v-col cols="12" md="6">
							<v-dialog ref="dialogDate" v-model="modal['date']" :return-value.sync="form['data']['date']" persistent width="290px">
								<template v-slot:activator="{ on, attrs }">
								<v-text-field :value="$moment.utc(form['data']['date']).format('DD MMM YYYY')" class="fsk-v-input" label="W.E.F *" name="date" readonly outlined dense hide-details="auto" v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="form['data']['date']" scrollable @input="$refs.dialogDate.save(form['data']['date'])">
								</v-date-picker>
							</v-dialog>
						</v-col>
						<v-col cols="12" md="6"></v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="form['data']['name']" class="fsk-v-input" label="Name *" name="name" type="text" outlined dense hide-details="auto"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="form['data']['code']" class="fsk-v-input" label="Code *" name="code" type="text" outlined dense hide-details="auto"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-select v-model="form['data']['vendor']" class="fsk-v-input" :items="['DDqarter', 'Tecx', 'Adidas', 'Shocks Auto', 'GoDaddy']" label="Vendor *" name="vendor" outlined dense hide-details="auto"></v-select>
						</v-col>
						<v-col cols="12" md="6">
							<v-select v-model="form['data']['category']" class="fsk-v-input" :items="['Automotive', 'Beauty', 'Clothing', 'Books', 'Entertainment', 'Software', 'Sports']" label="Category *" name="category" outlined dense hide-details="auto"></v-select>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="form['data']['cost']" class="fsk-v-input" label="Cost *" name="cost" type="text" outlined dense hide-details="auto"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="form['data']['duration']" class="fsk-v-input" label="Duration *" name="duration" type="text" outlined dense hide-details="auto"></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4 px-6">
					<v-spacer></v-spacer>
					<v-btn color="grey" text @click="hideForm()">Close</v-btn>
					<v-btn color="primary" text @click="submitForm()">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Status Dialog -->
		<v-dialog
			v-model="tag['show']"
			persistent
			max-width="400"
		>
		<v-card :loading="tag['loading']" :disabled="tag['loading']">
			<v-card-title class="headline">Update Status</v-card-title>

			<v-card-text style="max-height: 80vh;">
				<v-row>
					<v-col cols="12">
						<v-select v-model="tag['status']" class="fsk-v-input" :items="['Active', 'Discontinued', 'On Hold']" label="Status *" name="status" outlined dense :color="getStatusColor(tag['status'])" hide-details="auto"></v-select>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider></v-divider>
			<v-card-actions class="py-4 px-6">
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="hideStatus()">Close</v-btn>
				<v-btn color="primary" text @click="confirmStatus()">Save</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>

		<!-- Delete Dialog -->
		<v-dialog
			v-model="del['show']"
			persistent
			max-width="400"
		>
		<v-card :loading="del['loading']" :disabled="del['loading']">
			<v-card-title class="headline">Delete Service</v-card-title>

			<v-card-text>Please confirm the deletion of service <strong>{{ del['name'] }}</strong></v-card-text>

			<v-divider></v-divider>
			<v-card-actions class="py-4 px-6">
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="hideDelete()">Close</v-btn>
				<v-btn color="primary" text @click="confirmDelete()">Confirm</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		data: () => ({
			modal: {
				date: false
			},
			search: {
				show: false,
				input: ''
			},
			loading: false,
			reset: false,
			headers: [
				{
					text: 'Name',
					align: 'start',
					value: 'name',
				},
				{
					text: 'Vendor',
					value: 'vendor',
				},
				{
					text: 'Category',
					value: 'category',
				},
				{
					text: 'Cost',
					value: 'cost',
				},
				{
					text: 'W.E.F',
					value: 'date',
				},
				{
					text: 'Duration',
					value: 'duration',
				},
				{
					text: 'Status',
					value: 'status',
					align: 'center',
				},
				{ text: 'Actions', align: 'end', value: 'actions', sortable: false },
			],
			table: [],
			form: {
				create: true,
				show: false,
				loading: false,
				data: {
					id: '',
					date: '',
					name: '',
					code: '',
					vendor: '',
					category: '',
					cost: '',
					duration: '',
					status: '',
				}
			},
			tag: {
				show: false,
				loading: false,
				id: '',
				status: ''
			},
			del: {
				show: false,
				loading: false,
				id: '',
				name: ''
			}
		}),

		created() {
			this.initialize();
		},

		methods: {
			initialize () {
				this.table = [
					{
						id: 1,
						name: 'Website Hosting',
						code: 'S008005',
						vendor: 'GoDaddy',
						category: 'Software',
						cost: '5,000',
						duration: '12 Months',
						status: 'Active',
						date: new Date()
					},
					{
						id: 2,
						name: 'Fifa Pro Training',
						code: 'S008400',
						vendor: 'Tecx',
						category: 'Entertainment',
						cost: '50,000',
						duration: '30 Weeks',
						status: 'On Hold',
						date: new Date(Date.now() - 86400000)
					},
					{
						id: 3,
						name: 'Gym Training',
						code: 'S000907',
						vendor: 'Adidas',
						category: 'Sports',
						cost: '6 Months',
						duration: '1,500',
						status: 'Active',
						date: new Date(Date.now() - 3888000000)
					},
					{
						id: 4,
						name: 'Car AMC',
						code: 'S001895',
						vendor: 'Shocks Auto',
						category: 'Automotive',
						cost: '500',
						duration: '1 Year',
						status: 'Discontinued',
						date: new Date(Date.now() - 6480000000)
					},
					{
						id: 5,
						name: 'Office FNCS Session',
						code: 'S000910',
						vendor: 'DDqarter',
						category: 'Entertainment',
						cost: '65,000',
						duration: '1 Day',
						status: 'On Hold',
						date: new Date(Date.now() - 7344000000)
					},
				]
			},
			resetTable() {
				this.loading = true;

				setTimeout(() => {
					this.loading = false;
					this.reset = true;
					this.reset = false;
				}, 3000);
			},
			getStatusColor (status) {
				switch (status) {
					case 'On Hold':
						return 'orange'
					
					case 'Active':
						return 'success'
					
					case 'Discontinued':
						return 'red'
			
					default:
						return 'grey'
				}
			},
			editItem(item) {
				// Assign form data
				this.form['data']['id'] = item['id'];
				this.form['data']['date'] = item['date'];
				this.form['data']['name'] = item['name'];
				this.form['data']['code'] = item['code'];
				this.form['data']['vendor'] = item['vendor'];
				this.form['data']['category'] = item['category'];
				this.form['data']['cost'] = item['cost'];
				this.form['data']['duration'] = item['duration'];
				this.form['data']['status'] = item['status'];

				// Show dialog
				this.showForm('edit');
			},
			updateStatus(item) {
				// Assign data
				this.tag['id'] = item['id'];
				this.tag['status'] = item['status'];

				// Show dialog
				this.tag['show'] = true;
			},
			hideStatus() {
				// Reset data
				this.tag['id'] = '';
				this.tag['status'] = '';

				// Unset loading
				this.tag['loading'] = false;

				// Hide dialog
				this.tag['show'] = false;
			},
			confirmStatus() {
				// TODO: Add update status API
				console.log(this.tag['id']);

				// Set loading
				this.tag['loading'] = true;

				setTimeout(() => {
					// Hide dialog
					this.hideStatus();
				}, 3000);
			},
			deleteItem(item) {
				// Assign data
				this.del['id'] = item['id'];
				this.del['name'] = item['name'];

				// Show dialog
				this.del['show'] = true;
			},
			hideDelete() {
				// Reset data
				this.del['id'] = '';
				this.del['name'] = '';

				// Unset loading
				this.del['loading'] = false;

				// Hide dialog
				this.del['show'] = false;
			},
			confirmDelete() {
				// TODO: Add delete service API
				console.log(this.del['id']);

				// Set loading
				this.del['loading'] = true;

				setTimeout(() => {
					// Hide dialog
					this.hideDelete();
				}, 3000);
			},
			resetForm() {
				this.form['data']['id'] = '';
				this.form['data']['date'] = '';
				this.form['data']['name'] = '';
				this.form['data']['code'] = '';
				this.form['data']['vendor'] = '';
				this.form['data']['category'] = '';
				this.form['data']['cost'] = '';
				this.form['data']['duration'] = '';
				this.form['data']['status'] = '';
			},
			showForm(type = 'create') {
				if (type == 'edit') {
					this.form['create'] = false;
				}
				else {
					this.form['create'] = true;

					// Prepopulate data
					this.form['data']['date'] = this.$moment.utc(new Date()).format('YYYY-MM-DD');
				}

				// Show Dialog
				this.form['show'] = true;
			},
			hideForm() {
				// Hide dialog
				this.form['show'] = false;

				// Unset loading
				this.form['loading'] = false;

				// Clear form
				this.resetForm();
			},
			submitForm() {
				if (this.form['create']) {
					// TODO: Add create service API
					console.log(this.form['data']);

					// Set loading
					this.form['loading'] = true;
				}
				else {
					// TODO: Add edit service API
					console.log(this.form['data']);

					// Set loading
					this.form['loading'] = true;
				}
				setTimeout(() => {
					// Hide dialog
					this.hideForm();
				}, 3000);
			}
		}
	}
</script>
